import React from "react"
import { Link } from "gatsby"
import Button from "../shared/button"
import styled from "styled-components"

const Success = styled.div`
  background: transparent linear-gradient(94deg, #06a0e4 0%, #0071e9 100%) 0% 0%
    no-repeat padding-box;
`

const SuccessContent = () => (
  <Success className="max-w-full bg-blue-800 rounded shadow-lg mt-16 lg:mt-32 mb-12">
    <div className="px-8 py-6 text-center">
      <div className="flex justify-center mb-3">
        <div className="border-4 border-blue-100 w-10 lg:w-20 rounded"></div>
      </div>
      <h1 className="text-3xl lg:text-4xl font-bold text-blue-100 mb-5">
        {" "}
        Thanks for Signing Up!
      </h1>
      <p className="text-xl lg:text-2xl text-blue-100">
        A member of the Archerhub team will be reaching out to you shortly.{" "}
        <br className="hidden lg:block" /> In the meantime, take a look around
        our website.
      </p>
      <div className="flex flex-wrap lg:flex-no-wrap justify-center mt-10">
        <Link to="/shipper">
          <Button
            className="bg-blue-900 hover:bg-blue-800 text-blue-100 hover:text-blue-100 font-bold py-2 lg:py-4 px-4 lg:px-8 mb-5 lg:mb-0 md:mr-5 rounded"
            type="button"
          >
            Ship with Archerhub
          </Button>
        </Link>
        <Link to="/news">
          <Button
            className="bg-blue-900 hover:bg-blue-800 text-blue-100 hover:text-blue-100 font-bold py-2 lg:py-4 px-4 lg:px-8 rounded"
            type="button"
          >
            Read Archerhub News
          </Button>
        </Link>
      </div>
    </div>
  </Success>
)

export default SuccessContent
