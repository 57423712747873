import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SuccessContent from "../components/signup-success/success-content"

class SignupSuccess extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Success" conversion={true}/>
        <div className="container mx-auto justify-center p-5">
          <SuccessContent></SuccessContent>
        </div>
      </Layout>
    )
  }
}
export default SignupSuccess
